import axios from 'axios'

export default {
    async getUser(data) {
        try {
            const response = await axios.get(`/office/users/${data}`);
            return response.data
        }
        catch (error) {
            return Promise.reject();
        }
    },
    async getUsersOffice(data) {
        try {
            const response = await axios.get(`/office/users/office/${data}`);
            return response.data
        }
        catch (error) {
            return Promise.reject();
        }
    },
    async getOffice(data) {
        try {
            const response = await axios.get(`office/users/owner/${data}`);
            return response.data
        }
        catch (error) {
            return Promise.reject();
        }
    },
    async create(data) {
        try {
            const response = await axios.post('/office/user', {
                user: data.user,
                office: data.office,
                owner: data.owner
            });
            return response.data
        }
        catch (error) {
            return Promise.reject();
        }
    },
    async update(data) {
        try {
            const response = await axios.put(`/office/user/${data.id}`, {
                user: data.user,
                office: data.office,
                owner: data.owner
            });
            return response.data
        }
        catch (error) {
            return Promise.reject();
        }
    }
}
