<template>
  <div class="container-fluid p-0">
    <div class="row">
      <div class="col-12">
        <span class="text-white bg-primary rounded-pill py-1 px-3 d-table mb-3">Tasks Due</span>
      </div>
    </div>
    <div class="row">
      <div class="col-md-5 col-lg-4 col-xl-3">
        <div class="bg-white shadow-sm rounded-lg p-4 text-center">
          <i v-if="count>0" class="fal fa-exclamation-triangle fa-3x text-danger"></i>
          <i v-else class="fal fa-check-square fa-3x text-success"></i>
          <span class="d-block my-2">You have</span>
          <h3 v-if="count>0" class="text-danger m-0">{{count}}</h3>
          <span v-if="count>0"  class="d-block my-2">Upcoming pending tasks</span>
          <span v-else class="d-block my-2">No pending tasks</span>
        </div>
      </div>
      <div class="col-md-7 col-lg-8 col-xl-9 bg-white shadow-sm rounded-lg">
        <div v-if="this.$store.getters.discriminator==='administrator'" class="col-4 ml-0 p-0 mt-2">
          <multiselect v-model="oficeFilter" @input="filterData" :options="offices" :multiple="true" :close-on-select="false" :clear-on-select="false" :preserve-search="true" placeholder="Select Offices" label="office_name" track-by="id" :select-label="''" :deselect-label="''" @open="openBackgroundOpacity" @close="closeBackgroundOpacity">
            <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">{{ values.map(item=>{return item.office_name}).join(", ") }}</span></template>
          </multiselect>
        </div>
        <el-table 
        :data="this.tableData"
        class="w-100">
          <el-table-column sortable prop="corporate.name" label="Client"></el-table-column>
          <el-table-column sortable prop="title" label="Subject"></el-table-column>
          <el-table-column sortable prop="duedate" label="Remaining Days">
            <template slot-scope="scope">{{ 
                  diffDays(scope.row.duedate)
              }}
            </template>
          </el-table-column>
          <el-table-column sortable prop="duedate" label="Due Date">
            <template slot-scope="scope">{{ scope.row.duedate | moment("MM-DD-YYYY") }}</template>
          </el-table-column>
          <el-table-column sortable prop="priority" label="Priority"></el-table-column>
          <el-table-column sortable v-if="this.$store.getters.discriminator==='administrator'" prop="users.name" label="Assigned To"></el-table-column>
          <el-table-column prop="id" width="80">
            <template slot-scope="scope">
              <router-link :to="'/task/'+scope.row.id">
                  <el-button type="primary" size="small">View</el-button>
              </router-link>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>


<script>
import note from "@/services/api/note";
import office from "@/services/api/office";
import officeUser from "@/services/api/officeUser";
import Multiselect from 'vue-multiselect';

export default {
  components: {
    Multiselect
  },
  data() {
    return {
      data:[],    
      tableData: [],
      oficeFilter:[],
      offices: [],
      count: 0
    };
  },
  mounted() {
    switch (this.$store.getters.discriminator) {
      case "administrator":
        this.discriminator = "administrator";
        note.getTasks().then(response => {
            response =  response.filter(task=>task.status==="Pending");
            response.sort(this.compare);
            if(response.length>=5){
              response=response.slice(0, 5);
            }
            this.count=response.length;
            this.data = Object.assign([], response);
            this.tableData = response;
          });
          office.get().then(response => {
            this.offices = response;
          });
        break;
      case "office":
      case "employee":
        officeUser.getOffice(this.$store.getters.id).then(response => {
            let idOffice=response[0].office.id;
            note.getTasksOffice(idOffice).then(response => {
              response =  response.filter(task=>task.status==="Pending");
              response.sort(this.compare);
              if(response.length>=5){
                response=response.slice(0, 5);
              }
              this.count=response.length;
              this.data = Object.assign([], response);
              this.tableData = response;
            });
        });
        break;
    }
  },
  methods: {
    diffDays(dueDate){
      var start = new Date();
      var end = new Date(dueDate);

      var diff = new Date(end - start);
      var days=1;
      days = diff / 1000 / 60 / 60 / 24;


      if (isNaN(days)) {
          return 0
      } else {
          return Math.round(days + 1)
      }
    },
    compare(a, b) {
      const dateA = new Date(a.duedate);
      const dateB = new Date(b.duedate);
        if (dateA > dateB) return 1;
        if (dateB > dateA) return -1;
        const values = {
          'Low': 1, 'Medium': 2,'High': 3,'Urgent': 4
        }
        if(values[a.priority] > values[b.priority]) return -1;
        if(values[a.priority] < values[b.priority]) return 1;
        return 0;
    },
    filterData(){
      let idOffices = this.oficeFilter.map(office=>{
        return office.id;
      });
      
      this.tableData = Object.assign([], this.data);

      if(idOffices.length>0){
        this.tableData=this.tableData.filter(item=>idOffices.indexOf(item.officeId)!=-1);
      }

    },
  }
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
