import axios from 'axios'

export default {
    async getNotes() {
        try {
            const response = await axios.get('/notes');
            return response.data
        }
        catch (error) {
            return Promise.reject();
        }
    },
    async getNotesOffice(id) {
        try {
            
            const response = await axios.get(`/notesOffice/${id}`);
            return response.data
        }
        catch (error) {
            return Promise.reject();
        }
    },
    async getTasks() {
        try {
            
            const response = await axios.get('/tasks');
            return response.data
        }
        catch (error) {
            return Promise.reject();
        }
    },
    async getTask(id) {
        try {
            
            const response = await axios.put(`/task/${id}`);
            return response.data
        }
        catch (error) {
            return Promise.reject();
        }
    },
    async getTasksOffice(id) {
        try {
            
            const response = await axios.get(`/tasksOffice/${id}`);
            return response.data
        }
        catch (error) {
            return Promise.reject();
        }
    },
    async completeTask(data) {
        try {
            const response = await axios.put(`/task/complete/${data.id}`);
            return response.data
        }
        catch (error) {
            return Promise.reject();
        }
    },
    async create(data) {
        try {
            const response = await axios.post('/notes', {
                title: data.title,
                priority: data.priority,
                detail: data.detail,
                user: data.user,
                office: data.office,
                client: data.client,
                assignto: data.assignto,
                category: data.category,
                duedate: data.duedate,
                status: data.status
            });
            return response.data
        }
        catch (error) {
            return Promise.reject();
        }
    },
    async update(data) {
        try {
            const response = await axios.put(`/notes/${data.id}`, {
                title: data.title,
                priority: data.priority,
                detail: data.detail,
                user: data.user,
                office: data.office,
                client: data.client,
                assignto: data.assignto,
                category: data.category,
                duedate: data.duedate,
                status: data.status
            });
            return response.data
        }
        catch (error) {
            return Promise.reject();
        }
    },
    async delete(data) {
        try {
            const response = await axios.put(`/note/delete/${data.id}`, data);
            return response.data
        }
        catch (error) {
            return Promise.reject();
        }
    }
}
