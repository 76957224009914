import axios from 'axios'

export default {
    async get() {
        try {
            const response = await axios.get('/offices');
            return response.data
        }
        catch (error) {
            return Promise.reject();
        }
    },
    async create(data) {
        try {
            const response = await axios.post('/office', {
                office_name: data.office_name,
                manager_name: data.manager_name,
                email: data.email,
                dba: data.dba,
                phone: data.phone,
                fax: data.fax,
                ein: data.ein,
                address: data.address,
                image: data.image,
                state: data.state,
                city: data.city,
                zip: data.zip,
                office_status: data.office_status
            });
            return response.data
        }
        catch (error) {
            return Promise.reject();
        }
    },
    async update(data) {
        try {
            const response = await axios.put(`/office/${data.id}`, {
                office_name: data.office_name,
                manager_name: data.manager_name,
                email: data.email,
                old_email: data.old_email,
                dba: data.dba,
                phone: data.phone,
                fax: data.fax,
                ein: data.ein,
                address: data.address,
                state: data.state,
                city: data.city,
                zip: data.zip,
                image: data.image,
                office_status: data.office_status
            });
            return response.data
        }
        catch (error) {
            return Promise.reject();
        }
    },
    async delete(data) {
        try {
            const response = await axios.put(`/office/delete/${data.id}`, data);
            return response.data
        }
        catch (error) {
            return Promise.reject();
        }
    }
}
